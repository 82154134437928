import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormInput,
  CFormSelect,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { IoSearchSharp } from "react-icons/io5";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const Blogs = () => {
  const [inputData, setInputData] = useState({
    title: "",
    meta_description: "",
    uri_slug: "",
    author: "",
  });
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const [blogsList, setBlogsList] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [blogId, setBlogId] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [openViewModal, setOpenViewModal] = useState(false);
  const [blogDetails, setBlogDetails] = useState({});
  const [openImageUploadModal, setOpenImageUploadModal] = useState(false);
  const [imageId, setImageId] = useState("");
  const [recordsValue, setRecordsValue] = useState(10);
  const [inputText, setInputText] = useState("");
  const [numberValue, setNumberValue] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [blogContent, setBlogContent] = useState("");
  const textAreaRef = useRef(null);
  const editTextAreaRef = useRef(null);
  const [editBlogContent, setEditBlogContent] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    title: "",
    meta_description: "",
    uri_slug: "",
    author: "",
    blog_content: "",
    image: "",
  });
  const [editValidationErrors, setEditValidationErrors] = useState({
    title: "",
    meta_description: "",
    uri_slug: "",
    author: "",
    blog_content: "",
    image: "",
  });
  const [isShowButtonloading, setIsShowButtonloading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isEditImgBtnLoading, setIsEditImgBtnLoading] = useState(false);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "IMAGE",
      cell: (row) => (
        <div className="d-flex flex-column align-items-center gap-2">
          <img
            className="fs-7"
            style={{
              width: "120px",
              height: "120px",
              marginTop: "5px",
            }}
            src={row.blog_image_path ? row.blog_image_path : "--------"}
          />
          <p
            style={{ fontSize: "10px", color: "blue", cursor: "pointer" }}
            onClick={() => handleImageUploadModal(row)}
          >
            Change image
          </p>
        </div>
      ),
      width: "150px",
    },
    {
      name: "BLOG TITLE",
      cell: (row) => (
        <div className="fs-7">{row.title ? row.title : "--------"}</div>
      ),
    },
    {
      name: "AUTHOR",
      cell: (row) => (
        <div className="fs-7">{row.author ? row.author : "--------"}</div>
      ),
    },
    {
      name: "CREATED ON",
      cell: (row) => (
        <div className="fs-7">
          {row.created_at ? row.created_at : "--------"}
        </div>
      ),
    },
    {
      name: "MODIFIED ON",
      cell: (row) => (
        <div className="fs-7">
          {row.updated_at ? row.updated_at : "--------"}
        </div>
      ),
    },
    {
      name: "ACTIONS",
      cell: (row) => (
        <CDropdown variant="btn-group" direction="dropend">
          <CDropdownToggle color="secondary" size="sm">
            Action
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem onClick={() => handleOpenViewModal(row)}>
              View
            </CDropdownItem>
            <CDropdownItem onClick={() => handleEditModal(row)}>
              Edit
            </CDropdownItem>
            <CDropdownItem onClick={() => handleDeleteModal(row)}>
              Delete
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      ),
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "15px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      const cleanedTitle = value.replace(/\(([^)]*)\)/g, "$1");
      const uriSlugValue = cleanedTitle.replace(/\s+/g, "-").toLowerCase();
      const escapedUriSlug = uriSlugValue.replace(/[,:?+;']/g, (match) => {
        if (match === "?") return "";
        if (match === ":") return "";
        if (match === "+") return "-";
        if (match === ";") return "";
        if (match === "'") return "";
      });

      setInputData((prevState) => ({
        ...prevState,
        [name]: value,
        uri_slug: escapedUriSlug,
      }));
    } else {
      setInputData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };

  const handleImageUploadModal = (data) => {
    setImageId(data?.id);
    setOpenImageUploadModal(true);
  };

  const handleEditImage = async (e) => {
    e.preventDefault();
    setIsEditImgBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("blog_id", imageId);

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      const response = await axiosPrivate.post(
        "cms/blogs/update-image",
        formData,
        { headers }
      );

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAllBlogs();
        setOpenImageUploadModal(false);
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.status === false) {
        let errorMessage = error?.response?.data?.error;

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditImgBtnLoading(false);
    }
  };

  const handleAddBlog = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!inputData.title) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        title: "Title is required.",
      }));
      return;
    }

    if (!inputData.uri_slug) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        uri_slug: "URI slug is required.",
      }));
      return;
    }

    if (!inputData.meta_description) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        meta_description: "Meta description is required.",
      }));
      return;
    }

    if (!inputData.author) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        author: "Author is required.",
      }));
      return;
    }

    if (!blogContent) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        blog_content: "Content is required.",
      }));
      return;
    }

    if (Object.values(validationErrors).some((error) => error !== "")) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("title", inputData?.title);
      formData.append("uri_slug", inputData?.uri_slug);
      formData.append("blog_content", blogContent);
      formData.append("meta_description", inputData?.meta_description);
      formData.append("author", inputData?.author);
      formData.append("image", image);

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      const response = await axiosPrivate.post("cms/blogs/add", formData, {
        headers,
      });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAllBlogs();

        e.target.reset()

        setInputData({
          title: "",
          meta_description: "",
          uri_slug: "",
          author: "",
        });
        setBlogContent("");
        setImage(null);
      }
    } catch (error) {
      if (error?.response && error.response?.data?.status === false) {
        let errorMessage = error?.response?.data?.error;

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const getAllBlogs = async () => {
    try {
      const response = await axiosPrivate.get(
        `cms/blogs/get?page=1&limit=10&search=`
      );

      console.log('blogs', response?.data?.data?.list);

      if (response?.data?.status === true) {
        setBlogsList(response?.data?.data?.list);
      }
    } catch (error) {
      if (
        error?.response &&
        error?.response?.status === 404 &&
        error?.response?.data
      ) {
        toast.error("No blog records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while fetching blogs list.";
        if (error?.response && error?.response?.data) {
          errorMessage = error?.response?.data?.message;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setBlogId(data?.id);
  };

  const handleDeleteBlog = async () => {
    setIsDeleteBtnLoading(true);
    try {
      const response = await axiosPrivate.delete(
        `cms/blogs/delete?id=${blogId}`
      );
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAllBlogs();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.status === false) {
        let errorMessage = error?.response?.data?.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleEditModal = async (data) => {
    setEditedData({
      id: data?.id,
      title: data?.title,
      meta_description: data?.meta_description,
      uri_slug: data?.uri_slug,
      author: data?.author,
    });

    setEditBlogContent(data?.content);
    setOpenEditModal(true);
  };

  const handleTextareaChange = (event) => {
    setEditBlogContent(event?.target?.value);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      const cleanedTitle = value.replace(/\(([^)]*)\)/g, "$1");
      const uriSlugValue = cleanedTitle.replace(/\s+/g, "-").toLowerCase();
      const escapedUriSlug = uriSlugValue.replace(/[,:?+;']/g, (match) => {
        if (match === "?") return "";
        if (match === ":") return "";
        if (match === "+") return "-";
        if (match === ";") return "";
        if (match === "'") return "";
      });
      setEditedData((prevState) => ({
        ...prevState,
        [name]: value,
        uri_slug: escapedUriSlug,
      }));
    } else {
      setEditedData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setEditValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsEditBtnLoading(true);
    if (!editedData.title) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        title: "Title is required.",
      }));
      return;
    }

    if (!editedData.uri_slug) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        uri_slug: "URI slug is required.",
      }));
      return;
    }

    if (!editedData.meta_description) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        meta_description: "Meta description is required.",
      }));
      return;
    }

    if (!editedData.author) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        author: "Author is required.",
      }));
      return;
    }

    if (!editBlogContent) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        blog_content: "Content is required.",
      }));
      return;
    }

    if (Object.values(editValidationErrors).some((error) => error !== "")) {
      return;
    }
    try {
      const data = {
        id: editedData?.id,
        title: editedData?.title,
        meta_description: editedData?.meta_description,
        uri_slug: editedData?.uri_slug,
        author: editedData?.author,
        blog_content: editBlogContent,
      };

      const response = await axiosPrivate.put("cms/blogs/update", data);
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getAllBlogs();
        setOpenEditModal(false);
      } else if (response?.data?.status === false) {
        getAllBlogs();
        setOpenEditModal(false);
      }
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleOpenViewModal = (data) => {
    getSingleBlogDetails(data?.id);
    setOpenViewModal(true);
  };

  const getSingleBlogDetails = async (blogId) => {
    try {
      const response = await axiosPrivate.get(`cms/blogs/single?id=${blogId}`);

      if (response?.data?.status === true) {
        setBlogDetails(response?.data?.data?.blog);
      }
    } catch (error) {
      if (error?.response && error.response?.data?.status === false) {
        let errorMessage = error?.response?.data?.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleFilter = async () => {
    setIsShowButtonloading(true);
    try {
      const response = await axiosPrivate.get(
        `cms/blogs/get?page=1&limit=${recordsValue}&search=${inputText}`
      );

      if (response?.data?.status === true) {
        setBlogsList(response?.data?.data?.list);
        setTotalPages(response?.data?.data?.pagination?.total_records);
      } else if (response?.data?.status === false) {
        toast.error(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (
        error?.response &&
        error?.response?.status === 404 &&
        error?.response?.data
      ) {
        toast.error("No customer records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while fetching blogs list.";
        if (error?.response && error?.response?.data) {
          errorMessage = error?.response?.data?.message;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsShowButtonloading(false);
    }
  };

  const handlePrevClick = () => {
    const prevPage = Math.max(numberValue - 1, 1);
    setNumberValue(prevPage);
    pagination(prevPage, recordsValue);
  };

  const handleNextClick = async () => {
    const nextPage = numberValue + 1;
    if (nextPage <= totalPages) {
      setNumberValue(nextPage);
      pagination(nextPage, parseInt(recordsValue, 10));
    } else {
      toast.error("Already on the last page", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  };

  const pagination = async (pageNumber, limit) => {
    try {
      const response = await axiosPrivate.get(
        `cms/blogs/get?page=${pageNumber}&limit=${limit}&search=`
      );

      if (response?.data?.status === true) {
        setBlogsList(response?.data?.data?.list);
        setTotalPages(response?.data?.data?.pagination?.no_of_pages);
      } else {
        toast.error("No Blogs records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (
        error?.response &&
        error?.response?.status === 404 &&
        error?.response?.data
      ) {
        toast.error("No Blogs records found", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        let errorMessage = "An error occurred while fetching Blogs list.";
        if (error?.response && error?.response?.data) {
          errorMessage = error?.response?.data?.message;
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleParagraphClick = () => {
    setBlogContent(
      (prevContent) => prevContent + "<p> --- YOUR TEXT COMES HERE --- </p>\n"
    );
  };

  const handleHeaderClick = (type) => {
    setBlogContent(
      (prevContent) =>
        prevContent + `<h${type}> --- YOUR TEXT COMES HERE --- </h${type}>\n`
    );
  };

  const handleBoldClick = () => {
    const newText = "<b> --- YOUR TEXT --- </b>";
    insertTextAtCursor(newText);
  };

  const handleListClick = (type) => {
    setBlogContent(
      (prevContent) =>
        prevContent +
        `<${type}>\n<li> --- YOUR LIST ITEM 1 --- </li>\n<li> --- YOUR LIST ITEM 2 --- </li>\n</${type}>\n`
    );
  };

  const handleLinkClick = () => {
    const newText = '<a href="<YOUR WEB ADDRESS>"> --- DISPLAY TEXT --- </a>';
    insertTextAtCursor(newText);
  };

  const insertTextAtCursor = (newText) => {
    if (textAreaRef.current) {
      const textarea = textAreaRef.current;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;

      const textBefore = textarea.value.substring(0, startPos);
      const textAfter = textarea.value.substring(endPos, textarea.value.length);

      textarea.value = textBefore + newText + textAfter;

      const newCursorPosition = startPos + newText.length;
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  };

  const handleEditParagraphClick = () => {
    setEditBlogContent(
      (prevContent) => prevContent + "<p> --- YOUR TEXT COMES HERE --- </p>\n"
    );
  };

  const handleEditHeaderClick = (type) => {
    setEditBlogContent(
      (prevContent) =>
        prevContent + `<h${type}> --- YOUR TEXT COMES HERE --- </h${type}>\n`
    );
  };

  const handleEditBoldClick = () => {
    const newText = "<b> --- YOUR TEXT --- </b>";
    insertEditTextAtCursor(newText);
  };

  const handleEditListClick = (type) => {
    setEditBlogContent(
      (prevContent) =>
        prevContent +
        `<${type}>\n<li> --- YOUR LIST ITEM 1 --- </li>\n<li> --- YOUR LIST ITEM 2 --- </li>\n</${type}>\n`
    );
  };

  const handleEditLinkClick = () => {
    const newText = '<a href="<YOUR WEB ADDRESS>"> --- DISPLAY TEXT --- </a>';
    insertEditTextAtCursor(newText);
  };

  const insertEditTextAtCursor = (newText) => {
    if (editTextAreaRef.current) {
      const textarea = editTextAreaRef.current;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;

      const textBefore = textarea.value.substring(0, startPos);
      const textAfter = textarea.value.substring(endPos, textarea.value.length);

      textarea.value = textBefore + newText + textAfter;

      const newCursorPosition = startPos + newText.length;
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  const buttonDisabled =
    !inputData?.title ||
    !inputData?.uri_slug ||
    !inputData?.meta_description ||
    !inputData?.author ||
    !blogContent ||
    image === null;

  return (
    <>
      <CContainer>
        <CCard className="mb-5">
          <CCardHeader>
            <CCardTitle className="fs-6">ADD BLOG</CCardTitle>
          </CCardHeader>
          <CForm onSubmit={handleAddBlog}>
            <CCardBody>
              <CRow>
                <CCol className="col-md-5">
                  <div className="mb-3">
                    <CFormInput
                      type="text"
                      id="exampleFormControlInput1"
                      label="Blog Title"
                      placeholder="Blog Title"
                      className="form-control"
                      name="title"
                      value={inputData.title}
                      onChange={handleChange}
                    />
                    <div className="text-danger">{validationErrors.title}</div>
                  </div>
                  <div className="mb-3">
                    <CFormInput
                      disabled
                      type="text"
                      id="exampleFormControlInput1"
                      label="URI Slug"
                      placeholder="URI Slug"
                      className="form-control"
                      name="uri_slug"
                      value={inputData.uri_slug}
                      onChange={handleChange}
                    />
                    <div className="text-danger">
                      {validationErrors.uri_slug}
                    </div>
                  </div>
                  <div className="mb-3">
                    <CFormTextarea
                      className="form-control"
                      label="Meta Description (This goes in the web page description used for SEO)"
                      type="text"
                      placeholder="Meta Description"
                      name="meta_description"
                      value={inputData.meta_description}
                      onChange={handleChange}
                    ></CFormTextarea>
                    <div className="text-danger">
                      {validationErrors.meta_description}
                    </div>
                  </div>
                  <div className="mb-3">
                    <CFormInput
                      type="text"
                      id="exampleFormControlInput1"
                      label="Author"
                      placeholder="Author"
                      className="form-control"
                      name="author"
                      value={inputData.author}
                      onChange={handleChange}
                    />
                    <div className="text-danger">{validationErrors.author}</div>
                  </div>
                  <div className="mb-3">
                    <CFormInput
                      type="file"
                      onChange={handleImageChange}
                      className="form-control"
                      id="formFile"
                      label="Choose File"
                    />
                    <div className="text-danger">{validationErrors.image}</div>
                  </div>
                </CCol>
                <CCol className="col-md-7">
                  <p>
                    Make use of below operation to create an HTML Blog Content
                  </p>
                  <CButton size="sm" onClick={handleParagraphClick}>
                    PARAGRAPH
                  </CButton>
                  <CButton
                    onClick={() => handleHeaderClick(1)}
                    size="sm"
                    className="btn btn-secondary m-1"
                  >
                    HEADER 1
                  </CButton>
                  <CButton
                    onClick={() => handleHeaderClick(2)}
                    size="sm"
                    className="btn btn-secondary m-1"
                  >
                    HEADER 2
                  </CButton>
                  <CButton
                    onClick={() => handleHeaderClick(3)}
                    size="sm"
                    className="btn btn-secondary m-1"
                  >
                    HEADER 3
                  </CButton>
                  <CButton
                    onClick={() => handleHeaderClick(4)}
                    size="sm"
                    className="btn btn-secondary m-1"
                  >
                    HEADER 4
                  </CButton>
                  <CButton
                    onClick={handleBoldClick}
                    size="sm"
                    className="btn btn-info m-1"
                  >
                    BOLD TEXT
                  </CButton>
                  <CButton
                    onClick={() => handleListClick("ol")}
                    size="sm"
                    className="btn btn-info m-1"
                  >
                    ORDERED LIST
                  </CButton>
                  <CButton
                    onClick={() => handleListClick("ul")}
                    size="sm"
                    className="btn btn-info m-1"
                  >
                    UNORDERED LIST
                  </CButton>
                  <CButton
                    onClick={handleLinkClick}
                    size="sm"
                    className="btn btn-info m-1"
                  >
                    LINK
                  </CButton>
                  <div>
                    <CFormTextarea
                      rows="12"
                      className="form-control"
                      label="Content"
                      type="text"
                      placeholder="Content"
                      name="blog_content"
                      id="blog-content"
                      ref={textAreaRef}
                      value={blogContent}
                      onChange={(e) => setBlogContent(e.target.value)}
                    ></CFormTextarea>
                    <div className="text-danger">
                      {validationErrors.blog_content}
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton
                color="secondary"
                type="submit"
                disabled={buttonDisabled}
              >
                {loading ? (
                  <span className="d-flex align-items-center justify-content-center gap-2">
                    <CSpinner size="sm" /> {" Loading..."}
                  </span>
                ) : (
                  <span>ADD BLOG</span>
                )}
              </CButton>
            </CCardFooter>
          </CForm>
        </CCard>

        <CCard>
          <CCardHeader>
            <CCardTitle className="fs-6">BLOGS LIST</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CRow xs={{ gutter: 3 }}>
              <CCol sm={12} md={3}>
                <CFormSelect
                  name="recordsValue"
                  className="form-control"
                  value={recordsValue}
                  onChange={(e) => setRecordsValue(e.target.value)}
                  options={[
                    { label: "10 records", value: "10" },
                    { label: "20 records", value: "20" },
                    { label: "40 records", value: "40" },
                    { label: "60 records", value: "60" },
                    { label: "80 records", value: "80" },
                    { label: "100 records", value: "100" },
                  ]}
                />
              </CCol>
              <CCol sm={12} md={4}>
                <CFormInput
                  placeholder="Search blogs..."
                  prefix={<IoSearchSharp />}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </CCol>
              <CCol sm={12} md={3}>
                <CButton
                  color="secondary"
                  className="w-100"
                  onClick={handleFilter}
                >
                  {isShowButtonloading ? (
                    <span className="d-flex align-items-center justify-content-center gap-2">
                      <CSpinner size="sm" /> {" Loading..."}
                    </span>
                  ) : (
                    <span>
                      <FaArrowRight size={15} style={{ marginRight: "5px" }} />{" "}
                      SHOW
                    </span>
                  )}
                </CButton>
              </CCol>
            </CRow>

            <div className="d-flex justify-content-end mt-4">
              <div>
                <CButton
                  disabled={numberValue === 1}
                  onClick={handlePrevClick}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  {" "}
                  <FaArrowLeft style={{ marginRight: "5px" }} /> PREV
                </CButton>
              </div>
              <div className="border rounded text-center p-1">
                Page No.{numberValue}
              </div>
              <div>
                <CButton
                  onClick={handleNextClick}
                  disabled={numberValue >= totalPages}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  NEXT
                  <FaArrowRight style={{ marginLeft: "5px" }} />
                </CButton>
              </div>
            </div>

            <DataTable
              columns={updatedColumns}
              data={blogsList}
              className="mt-3"
              highlightOnHover
            />
          </CCardBody>

          <CCardFooter>
            <div className="d-flex justify-content-end mt-1">
              <div>
                <CButton
                  disabled={numberValue === 1}
                  onClick={handlePrevClick}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  {" "}
                  <FaArrowLeft style={{ marginRight: "5px" }} /> PREV
                </CButton>
              </div>
              <div className="border rounded text-center p-1">
                Page No.{numberValue}
              </div>
              <div>
                <CButton
                  onClick={handleNextClick}
                  disabled={numberValue >= totalPages}
                  className="d-flex align-items-center justify-content-center"
                  color="secondary"
                >
                  NEXT
                  <FaArrowRight style={{ marginLeft: "5px" }} />
                </CButton>
              </div>
            </div>
          </CCardFooter>
        </CCard>
      </CContainer>
      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE BLOG
            </CModalTitle>
          </CModalHeader>
          <CModalBody>Are you sure you want to delete this blog ?</CModalBody>
          <CModalFooter>
            <CButton color="dark" onClick={() => setOpenDeleteModal(false)}>
              Close
            </CButton>
            <CButton color="success" onClick={handleDeleteBlog}>
              {isDeleteBtnLoading ? (
                <span className="d-flex align-items-center justify-content-center gap-2">
                  <CSpinner size="sm" /> {" Loading..."}
                </span>
              ) : (
                <span>CONFIRM</span>
              )}
            </CButton>
          </CModalFooter>
        </CModal>
      ) : null}

      {openEditModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="LiveDemoExampleLabel"
        >
          <CModalHeader>
            <CModalTitle className="fs-6" id="VerticallyCenteredExample">
              EDIT BLOG
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormInput
                      type="text"
                      id="exampleFormControlInput1"
                      label="Blog Title"
                      placeholder="Blog Title"
                      className="form-control"
                      name="title"
                      value={editedData.title}
                      onChange={handleEditChange}
                    />
                    <div className="text-danger">
                      {editValidationErrors.title}
                    </div>
                  </div>
                  <div className="mb-3">
                    <CFormInput
                      type="text"
                      disabled
                      id="exampleFormControlInput1"
                      label="URI Slug"
                      placeholder="URI Slug"
                      className="form-control"
                      name="uri_slug"
                      value={editedData.uri_slug}
                      onChange={handleEditChange}
                    />
                    <div className="text-danger">
                      {editValidationErrors.uri_slug}
                    </div>
                  </div>
                  <div className="mb-3">
                    <CFormTextarea
                      className="form-control"
                      label="Meta Description (This goes in the web page description used for SEO)"
                      type="text"
                      placeholder="Meta Description"
                      name="meta_description"
                      value={editedData.meta_description}
                      onChange={handleEditChange}
                    ></CFormTextarea>
                    <div className="text-danger">
                      {editValidationErrors.meta_description}
                    </div>
                  </div>
                  <div className="mb-3">
                    <CFormInput
                      type="text"
                      id="exampleFormControlInput1"
                      label="Author"
                      placeholder="Author"
                      className="form-control"
                      name="author"
                      value={editedData.author}
                      onChange={handleEditChange}
                    />
                    <div className="text-danger">
                      {editValidationErrors.author}
                    </div>
                  </div>
                </CCol>
                <CCol>
                  <p>
                    Make use of below operation to create an HTML Blog Content
                  </p>
                  <CButton size="sm" onClick={handleEditParagraphClick}>
                    PARAGRAPH
                  </CButton>
                  <CButton
                    onClick={() => handleEditHeaderClick(1)}
                    size="sm"
                    className="btn btn-secondary m-1"
                  >
                    HEADER 1
                  </CButton>
                  <CButton
                    onClick={() => handleEditHeaderClick(2)}
                    size="sm"
                    className="btn btn-secondary m-1"
                  >
                    HEADER 2
                  </CButton>
                  <CButton
                    onClick={() => handleEditHeaderClick(3)}
                    size="sm"
                    className="btn btn-secondary m-1"
                  >
                    HEADER 3
                  </CButton>
                  <CButton
                    onClick={() => handleEditHeaderClick(4)}
                    size="sm"
                    className="btn btn-secondary m-1"
                  >
                    HEADER 4
                  </CButton>
                  <CButton
                    onClick={handleEditBoldClick}
                    size="sm"
                    className="btn btn-info m-1"
                  >
                    BOLD TEXT
                  </CButton>
                  <CButton
                    onClick={() => handleEditListClick("ol")}
                    size="sm"
                    className="btn btn-info m-1"
                  >
                    ORDERED LIST
                  </CButton>
                  <CButton
                    onClick={() => handleEditListClick("ul")}
                    size="sm"
                    className="btn btn-info m-1"
                  >
                    UNORDERED LIST
                  </CButton>
                  <CButton
                    onClick={handleEditLinkClick}
                    size="sm"
                    className="btn btn-info m-1"
                  >
                    LINK
                  </CButton>
                  <div className="mb-2">
                    <CFormTextarea
                      rows="6"
                      className="form-control"
                      label="Content"
                      ref={editTextAreaRef}
                      type="text"
                      placeholder="Content"
                      value={editBlogContent}
                      onChange={handleTextareaChange}
                    ></CFormTextarea>
                    <div className="text-danger">
                      {editValidationErrors.blog_content}
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <CButton color="secondary" type="submit">
                {isEditBtnLoading ? (
                  <span className="d-flex align-items-center justify-content-center gap-2">
                    <CSpinner size="sm" />
                    {" Loading..."}
                  </span>
                ) : (
                  "Save Changes"
                )}
              </CButton>
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openViewModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle className="fs-6" id="VerticallyCenteredExample">
              BLOG VIEW
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CTable className="table table-bordered m-b-10 ">
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell className="text-center">
                    {blogDetails?.title}
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                <CTableRow>
                  <CTableDataCell className="text-center">
                    <h6>Meta Description</h6>
                    <p>{blogDetails?.meta_description}</p>
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
            <div>
              <img
                src={blogDetails?.blog_image_path}
                alt="blogimage"
                className="img-responsive w-100"
              />
            </div>
            <p dangerouslySetInnerHTML={{ __html: blogDetails?.content }} />
          </CModalBody>
          <CModalFooter>
            <CButton
              className="btn btn-secondary"
              onClick={() => setOpenViewModal(false)}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      ) : null}

      {openImageUploadModal ? (
        <CModal
          alignment="center"
          visible={openImageUploadModal}
          onClose={() => setOpenImageUploadModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample">
              Change Image
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditImage}>
            <CModalBody>
              <div className="mb-3">
                <CFormInput
                  type="file"
                  onChange={handleImageChange}
                  className="form-control"
                  id="formFile"
                  label="Choose File"
                />
              </div>
            </CModalBody>
            <CModalFooter>
              <div>
                <CButton
                  color="secondary"
                  type="submit"
                  disabled={image === null ? true : false}
                >
                  {isEditImgBtnLoading ? (
                    <span className="d-flex align-items-center justify-content-center gap-2">
                      <CSpinner size="sm" />
                      {" Loading..."}
                    </span>
                  ) : (
                    "Save Changes"
                  )}
                </CButton>
              </div>
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </>
  );
};

export default Blogs;
